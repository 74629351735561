import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'
import {
  getEscalations,
  createEscalation,
  deleteEscalation,
  createEscalationError,
} from 'containers/EscalationData/actions'
import {
  selectEscalations,
  selectEscalationsLoading,
  selectDeletingEscalations,
  selectCreatingEscalation,
  selectCreatingEscalationError,
} from 'containers/EscalationData/selectors'
import permissionChecker from 'containers/PermissionChecker'
import BorderedCard from 'components/BorderedCard'
import DataTable from 'components/DataTable'
import SectionHeader from 'components/SectionHeader'
import Button from 'components/Button'
import TextLink from 'components/TextLink'
import Icon from 'components/Icon'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import AddEscalationModal from './AddEscalationModal'
import qs from 'query-string'
import UserWidget from 'components/UserWidget'

export class Escalation extends React.Component {
  constructor(props) {
    super(props)
    if (!props.loading && props.escalations.isEmpty()) {
      props.getEscalations()
    }
    this.state = {
      escalationModalOpen: false,
    }
  }
  styles = {
    container: {
      padding: '1em',
      height: '100%',
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
      gridRowGap: '1em',
    },
    topGuy: {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
    },
  }

  openEscalationModal = () => {
    this.setState({ escalationModalOpen: true })
  }

  closeEscalationModal = () => {
    this.setState({ escalationModalOpen: false })
  }

  getEscalationData = () => {
    return this.props.escalations
      .map(esc => ({
        title: esc.get('title'),
        description: esc.get('description'),
        createdBy: esc.get('createdBy'),
        srn: esc.get('srn'),
        delete: esc.get('srn'),
        resourceId: esc.get('resourceId'),
      }))
      .toList()
      .toJS()
  }

  handleNav = params => {
    this.props.push({
      pathname: '/App/EscalationDetails',
      search: qs.stringify({
        srn: params.srn,
      }),
    })
  }

  render() {
    const canEdit = this.props.userHasPermission({
      permissionName: 'edit.escalations',
    })

    return (
      <div style={this.styles.container}>
        <div style={this.styles.topGuy}>
          <SectionHeader>
            <FormattedMessage {...messages.allEscalationTitle} />
          </SectionHeader>
          <div>
            {canEdit && (
              <Button onClick={this.openEscalationModal} color="primary">
                <Icon fa name="plus" style={{ marginRight: '0.5em' }} />
                <FormattedMessage {...messages.addEscalation} />
              </Button>
            )}
          </div>
        </div>
        <BorderedCard style={this.styles.list}>
          {this.props.loading ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <SquareLoadingAnimation />
            </div>
          ) : (
            <DataTable
              autosize={false}
              customColumnConfig={{
                title: {
                  flex: 2,
                },
                description: {
                  flex: 2,
                },
                createdBy: {
                  headerName: 'Created By',
                  flex: 1,
                  cellRendererFramework: params => {
                    return <UserWidget srn={params.data.createdBy} table />
                  },
                },
                srn: {
                  hide: true,
                },
                resourceId: {
                  hide: true,
                },
                delete: {
                  width: 50,
                  minWidth: 50,
                  pinned: 'right',
                  headerName: '',
                  enableRowGroup: false,
                  menuTabs: [],
                  suppressMenu: true,
                  cellStyle: {
                    padding: '0 0.5em',
                  },
                  cellRendererFramework: params => {
                    if (
                      !this.props.userHasPermission({
                        permissionName: 'edit.escalations',
                        resourceId: params.data.resourceId,
                      })
                    ) {
                      return null
                    }

                    return (
                      <TextLink
                        color="primary"
                        onClick={() => {
                          this.props.deleteEscalation(params.data.delete)
                        }}
                        disabled={this.props.deleting.get(params.data.delete)}
                        title="Delete"
                      >
                        <Icon fa name="trash-alt" />
                      </TextLink>
                    )
                  },
                },
              }}
              onDoubleClickRow={this.handleNav}
              data={this.getEscalationData()}
            />
          )}
        </BorderedCard>
        {this.state.escalationModalOpen && (
          <AddEscalationModal
            create={this.props.createEscalation}
            close={this.closeEscalationModal}
            creating={this.props.creating}
            error={this.props.creatingError}
            clearError={() => this.props.createEscalationError({ error: null })}
          />
        )}
      </div>
    )
  }
}

Escalation.propTypes = {
  loading: PropTypes.bool,
  creating: PropTypes.bool,
  escalations: ImmutablePropTypes.map,
  getEscalations: PropTypes.func,
  createEscalation: PropTypes.func,
  push: PropTypes.func,
  userHasPermission: PropTypes.func.isRequired,
  deleteEscalation: PropTypes.func,
  createEscalationError: PropTypes.func,
  deleting: ImmutablePropTypes.map,
  creatingError: PropTypes.string,
}

const mapStateToProps = createStructuredSelector({
  escalations: selectEscalations,
  loading: selectEscalationsLoading,
  deleting: selectDeletingEscalations,
  creating: selectCreatingEscalation,
  creatingError: selectCreatingEscalationError,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEscalations,
      push,
      createEscalation,
      deleteEscalation,
      createEscalationError,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, permissionChecker)(Escalation)
