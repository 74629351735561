import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import Icon from 'components/Icon'
import BorderedCard from 'components/BorderedCard'
import TextLink from 'components/TextLink'

import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap'

import DetailInputs from './DetailInputs'
import SwimlaneInputs from './SwimlaneInputs'
import FilterInputs from './FilterInputs'
import RuleInputs from './RuleInputs'
import { List } from 'immutable'

const WIZARDS = { DETAILS: 1, SWIMLANES: 2, FILTERS: 3, RULES: 4 }

const styles = {
  step: {
    fontWeight: 400,
    fontSize: '1em',
    marginBottom: '1em',
  },
  buttons: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    columnGap: '1em',
    justifyContent: 'center',
  },
  forward: {
    display: 'flex',
    alignItems: 'center',
  },
}

const AddEscalationModal = props => {
  const [wizardMode, setWizardMode] = useState(WIZARDS.DETAILS)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [swimlanes, setSwimlanes] = useState(List())
  const [filters, setFilters] = useState(List())
  const [rules, setRules] = useState(List())
  const [shouldClose, setShouldClose] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)

  const nextStep = () => {
    if (wizardMode < WIZARDS.RULES) {
      setWizardMode(wizardMode + 1)
    } else {
      setShouldClose(true)
      props.create({
        title: title,
        description: description,
        swimlanes: swimlanes,
        filters: filters,
        rules: rules,
      })
    }
  }

  const justFinish = () => {
    setShouldClose(true)
    props.create({
      title: title,
      description: description,
      swimlanes: swimlanes,
      filters: filters,
      rules: rules,
    })
  }

  useEffect(() => {
    if (!props.creating && shouldClose && !props.error) {
      closeOut()
    } else if (!props.creating && props.error) {
      setError()
    }
  }, [props.creating])

  const setError = () => {
    setErrorMsg(props.error)
    props.clearError()
    setTimeout(() => {
      setErrorMsg(null)
      props.close()
    }, 5000)
  }

  const closeOut = () => {
    setWizardMode(0)
    props.close()
  }

  const prevStep = () => {
    if (wizardMode > WIZARDS.DETAILS) {
      setWizardMode(wizardMode - 1)
    }
  }

  const renderBody = () => {
    switch (wizardMode) {
      case WIZARDS.DETAILS:
        return renderDetails()
      case WIZARDS.SWIMLANES:
        return renderSwimlanes()
      case WIZARDS.FILTERS:
        return renderFilters()
      case WIZARDS.RULES:
        return renderRules()
      default:
        return null
    }
  }

  const renderDetails = () => {
    return (
      <Fragment>
        <div style={styles.step}>Step 1</div>
        <BorderedCard style={{ height: '85%' }}>
          <DetailInputs
            title={title}
            description={description}
            setTitle={setTitle}
            setDescription={setDescription}
          />
        </BorderedCard>
      </Fragment>
    )
  }

  const renderSwimlanes = () => {
    return (
      <Fragment>
        <div style={styles.step}>Step 2</div>
        <BorderedCard style={{ height: '85%' }}>
          <SwimlaneInputs swimlanes={swimlanes} setSwimlanes={setSwimlanes} />
        </BorderedCard>
      </Fragment>
    )
  }

  const renderFilters = () => {
    return (
      <Fragment>
        <div style={styles.step}>Step 3</div>
        <BorderedCard style={{ height: '85%' }}>
          <FilterInputs filters={filters} setFilters={setFilters} />
        </BorderedCard>
      </Fragment>
    )
  }

  const renderRules = () => {
    return (
      <Fragment>
        <div style={styles.step}>Step 4</div>
        <BorderedCard style={{ height: '85%' }}>
          <RuleInputs canEdit={true} rules={rules} setRules={setRules} />
        </BorderedCard>
      </Fragment>
    )
  }

  return (
    <Modal isOpen={true} size="lg">
      {errorMsg && <Alert color="danger">{errorMsg}</Alert>}
      <ModalHeader>Add Escalation Wizard</ModalHeader>
      <ModalBody style={{ minHeight: '400px' }}>{renderBody()}</ModalBody>
      <ModalFooter style={styles.buttons}>
        <div>
          <TextLink color="primary" onClick={props.close}>
            Cancel
          </TextLink>
        </div>
        <div style={styles.forward}>
          {wizardMode === WIZARDS.RULES ? (
            <div>&nbsp;</div>
          ) : (
            <Button
              disabled={title.length < 1 || props.creating || errorMsg}
              color="success"
              style={{ marginRight: '1em' }}
              onClick={justFinish}
            >
              Skip and Create
            </Button>
          )}
          {wizardMode > 1 && (
            <Button
              style={{ marginRight: '0.5em' }}
              color="primary"
              onClick={prevStep}
              outline
              disabled={props.creating || errorMsg}
            >
              <Icon fa name="angle-left" />
            </Button>
          )}
          <Button
            color="primary"
            onClick={nextStep}
            disabled={title.length < 1 || props.creating || errorMsg}
          >
            {wizardMode === WIZARDS.RULES ? `Create` : 'Next'}
            {!wizardMode === WIZARDS.RULES && (
              <Icon style={{ marginLeft: '0.5em' }} fa name="angle-right" />
            )}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

AddEscalationModal.propTypes = {
  close: PropTypes.func,
  create: PropTypes.func,
  clearError: PropTypes.func,
  creating: PropTypes.bool,
  error: PropTypes.string,
}

export default AddEscalationModal
